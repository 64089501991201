import React from 'react';
import {
  Grid,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import useCreateTenantData from '../../../hooks/useCreateTenantData';
import useCreateUserData from '../../../hooks/useCreateUserData';

const RegisterTenantAccountConfirm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { createTenant } = useCreateTenantData();
  const { createUser } = useCreateUserData();

  if (!location.state) {
    console.error('Error: location.state is null');
    navigate('/register');
    return null;
  }

  const { tenantData, userData } = location.state;

  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';

  const handleBack = () => {
    navigate('/register');
  };

  const handleCreateConfirm = async () => {
    const formTenantData = new FormData();
    Object.keys(tenantData).forEach((key) =>
      formTenantData.append(
        key,
        tenantData[key as keyof typeof tenantData] as string,
      ),
    );

    try {
      const result = await createTenant(
        `${API_ENDPOINT}/tenants`,
        formTenantData,
      );
      console.log(result);
      if (result) {
        userData.tenant_id = result.id;
        const formUserData = new FormData();
        Object.keys(userData).forEach((key) =>
          formUserData.append(
            key,
            userData[key as keyof typeof userData] as string,
          ),
        );
        await createUser(`${API_ENDPOINT}/users`, formUserData);
      }
      navigate('/register-complete');
    } catch (error) {
      console.error('新規会員登録中にエラーが発生しました', error);
    }
  };

  return (
    <Box sx={{ padding: 4, background: 'white', overflow: 'auto' }}>
      <Grid container spacing={2} justifyContent="center" sx={{ padding: 4 }}>
        <TableContainer component={Grid}>
          <Table sx={{ minWidth: '100%' }} aria-label="confirmation table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'black',
                  '& th': { color: 'white', fontWeight: 'bold' },
                }}
              >
                <TableCell sx={{ color: 'white' }}>項目</TableCell>
                <TableCell sx={{ color: 'white' }}>内容</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>メールアドレス</TableCell>
                <TableCell>{userData.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ユーザー名</TableCell>
                <TableCell>{userData.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>組織名</TableCell>
                <TableCell>{tenantData.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>プラン</TableCell>
                <TableCell>{tenantData.plan_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>住所</TableCell>
                <TableCell>{tenantData.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>配送先住所</TableCell>
                <TableCell>{tenantData.shipping_address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>電話番号</TableCell>
                <TableCell>{tenantData.phone_number}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          <Grid item>
            <Button
              variant="contained"
              sx={{ marginRight: 2 }}
              onClick={handleBack}
            >
              戻る
            </Button>
            <Button variant="contained" onClick={handleCreateConfirm}>
              確定
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterTenantAccountConfirm;
