import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Switch,
  Box,
  Typography,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { userIdState } from '../../../state/user/userState';
import useFetchUserData from '../../../hooks/useFetchUserData';
import useFetchRcEquipmentsData from '../../../hooks/useFetchRcEquipmentsData';
import { RcEquipmentResponse, UpdateRcEquipment } from '../../../types';
import useUpdateRcEquipmentData from '../../../hooks/useUpdateRcEquipmentData';
import useUpdateRcEquipmentStatus from '../../../hooks/useUpdateRcEquipmentStatus';

const RcEquipmentsTable: React.FC = () => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newEditName, setNewEditName] = useState('');
  const [newEditDescription, setNewEditDescription] = useState('');
  const [newEditStatus, setNewEditStatus] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const { updateRcEquipment } = useUpdateRcEquipmentData();
  const { updateRcEquipmentStatus } = useUpdateRcEquipmentStatus();
  const [editingRow, setEditingRow] = useState<RcEquipmentResponse | null>(
    null,
  );
  const token = localStorage.getItem('token') || '';
  const userId = useRecoilValue(userIdState);
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';

  const userUrl = `${API_ENDPOINT}/users/${userId}`;
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useFetchUserData(userUrl, token, false);

  const rcEquipmentsUrl = userData
    ? `${API_ENDPOINT}/rc-equipments/tenant/${userData.userTenant.tenantId}`
    : '';
  const {
    data: rcEquipmentsData,
    loading: rcEquipmentsLoading,
    error: rcEquipmentsError,
  } = useFetchRcEquipmentsData(rcEquipmentsUrl, token, refreshData);

  const filteredRows = rcEquipmentsData?.rcEquipments
    ? rcEquipmentsData.rcEquipments
    : [];

  const sortedRows = filteredRows?.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  const rows: RcEquipmentResponse[] = sortedRows.map((rcEquipmentData) => ({
    id: rcEquipmentData.id,
    equipmentSid: rcEquipmentData.equipmentSid,
    name: rcEquipmentData.name,
    description: rcEquipmentData.description,
    pinNum: rcEquipmentData.pinNum,
    status: rcEquipmentData.status,
    antennaId: rcEquipmentData.antennaId,
    tenantId: rcEquipmentData.tenantId,
  }));

  if (userLoading || rcEquipmentsLoading) {
    return <CircularProgress />;
  }
  if (userError) return <p>Error</p>;
  if (
    rcEquipmentsError &&
    rcEquipmentsError.message !== 'Request failed with status code 401' &&
    rcEquipmentsError.message !== 'Request failed with status code 404'
  ) {
    return <p>Error</p>;
  }

  const refreshRcEquipmentData = () => {
    setRefreshData((prev) => !prev);
  };

  const handleEditClick = (row: RcEquipmentResponse) => {
    setEditingRow(row);
    setNewEditName(row.name);
    setNewEditDescription(row.description);
    setNewEditStatus(row.status);
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleConfirm = async () => {
    if (!editingRow) return;

    const updatedRcEquipmentData: UpdateRcEquipment = {
      name: newEditName,
      description: newEditDescription,
      equipmentSid: editingRow.equipmentSid,
      pinNum: editingRow.pinNum,
      status: newEditStatus,
      antennaId: editingRow.antennaId,
      tenantId: editingRow.tenantId,
    };

    try {
      const result = await updateRcEquipment(
        `${API_ENDPOINT}/rc-equipments/${editingRow.id}`,
        token,
        updatedRcEquipmentData,
      );
      if (result) {
        refreshRcEquipmentData();
      }
    } catch (error) {
      console.error('機器の更新中にエラーが発生しました', error);
    }

    handleDialogClose();
  };

  const handleStatusChange = async (
    row: RcEquipmentResponse,
    status: boolean,
  ) => {
    const confirmMessage = status
      ? '電源をONにしますか？'
      : '電源をOFFにしますか？';
    if (!window.confirm(confirmMessage)) {
      return;
    }

    const updateUrl = `${API_ENDPOINT}/rc-equipments/update_rc_equipment_status/${row.antennaId}/${row.pinNum}?status=${status}`;
    try {
      await updateRcEquipmentStatus(updateUrl, token);
      refreshRcEquipmentData();
    } catch (error) {
      console.error('機器の状態更新中にエラーが発生しました', error);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6" component="div">
          遠隔制御機器一覧
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={refreshRcEquipmentData}
        >
          更新
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'black',
                '& th': { color: 'white', fontWeight: 'bold' },
              }}
            >
              <TableCell sx={{ color: 'white' }}>機器ID</TableCell>
              <TableCell sx={{ color: 'white' }}>機器電源状態</TableCell>
              <TableCell sx={{ color: 'white' }}>アンテナID</TableCell>
              <TableCell sx={{ color: 'white' }}>名称</TableCell>
              <TableCell sx={{ color: 'white' }}>説明</TableCell>
              <TableCell sx={{ color: 'white' }}></TableCell>
              <TableCell sx={{ color: 'white' }}></TableCell>
              <TableCell sx={{ color: 'white' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  {row.equipmentSid}
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  <Switch checked={row.status} disabled />
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {row.antennaId}
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {row.name}
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {row.description}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleStatusChange(row, true)}
                  >
                    ON
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleStatusChange(row, false)}
                    sx={{ ml: 1 }}
                  >
                    OFF
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => handleEditClick(row)}
                    sx={{ ml: 1 }}
                  >
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={editDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>機器の編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="新しい名称"
            type="text"
            fullWidth
            variant="standard"
            value={newEditName}
            onChange={(e) => setNewEditName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="新しい説明"
            type="text"
            fullWidth
            variant="standard"
            value={newEditDescription}
            onChange={(e) => setNewEditDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDialogClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RcEquipmentsTable;
