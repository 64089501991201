import React, { useEffect } from 'react';
import theme from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import RegisterConfirm from './pages/RegisterConfirm';
import RegisterComplete from './pages/RegisterComplete';
import ResetPassword from './pages/ResetPassword';
import NewPassword from './pages/NewPassword';
import Dashboard from './pages/Dashboard';
import Images from './pages/Images';
import Users from './pages/Users';
import Cameras from './pages/Cameras';
import TenantAccount from './pages/TenantAccount';
import UserAccount from './pages/UserAccount';
import RcEquipments from './pages/RcEquipments';
import { RecoilRoot } from 'recoil';
import ReactGA from 'react-ga4';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-1LRS14NGWK');
    ReactGA.send({
      hitType: 'pageview',
      page_path: location.pathname + location.search,
    });
  }, [location]);

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register-confirm" element={<RegisterConfirm />} />
          <Route path="/register-complete" element={<RegisterComplete />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/new-password/:token" element={<NewPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/images" element={<Images />} />
          <Route path="/users" element={<Users />} />
          <Route path="/cameras" element={<Cameras />} />
          <Route path="/tenant-account" element={<TenantAccount />} />
          <Route path="/user-account" element={<UserAccount />} />
          <Route path="/rc-equipments" element={<RcEquipments />} />
        </Routes>
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default App;
