import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { userIdState } from '../../../state/user/userState';
import CircularProgress from '@mui/material/CircularProgress';
import useFetchUserData from '../../../hooks/useFetchUserData';
import useFetchCamerasData from '../../../hooks/useFetchCamerasData';
import { CameraResponse, UpdateCamera } from '../../../types';
import useUpdateCameraData from '../../../hooks/useUpdateCameraData';

const CamerasTable: React.FC = () => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newEditName, setNewEditName] = useState('');
  const [newEditDescription, setNewEditDescription] = useState('');
  const [refreshData, setRefreshData] = useState(false);
  const { updateCamera } = useUpdateCameraData();
  const [editingRow, setEditingRow] = useState<CameraResponse | null>(null);
  const token = localStorage.getItem('token') || '';
  const userId = useRecoilValue(userIdState);
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';

  const userUrl = `${API_ENDPOINT}/users/${userId}`;
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useFetchUserData(userUrl, token, false);

  const camerasUrl = userData
    ? `${API_ENDPOINT}/cameras/tenant/${userData.userTenant.tenantId}`
    : '';
  const {
    data: camerasData,
    loading: camerasLoading,
    error: camerasError,
  } = useFetchCamerasData(camerasUrl, token, refreshData);

  const filteredRows = camerasData?.cameras ? camerasData.cameras : [];

  const sortedRows = filteredRows?.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  const rows: CameraResponse[] = sortedRows.map((cameraData) => ({
    id: cameraData.id,
    cameraSid: cameraData.cameraSid,
    name: cameraData.name,
    description: cameraData.description,
    antennaId: cameraData.antennaId,
    tenantId: cameraData.tenantId,
  }));

  if (userLoading || camerasLoading) {
    return <CircularProgress />;
  }
  if (userError) return <p>Error</p>;
  if (
    camerasError &&
    camerasError.message !== 'Request failed with status code 401' &&
    camerasError.message !== 'Request failed with status code 404'
  ) {
    return <p>Error</p>;
  }

  const refreshCameraData = () => {
    setRefreshData((prev) => !prev);
  };

  const handleEditClick = (row: CameraResponse) => {
    setEditingRow(row);
    setNewEditName(row.name);
    setNewEditDescription(row.description);
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleConfirm = async () => {
    if (!editingRow) return;

    const updatedCameraData: UpdateCamera = {
      name: newEditName,
      description: newEditDescription,
      cameraSid: editingRow.cameraSid,
      antennaId: editingRow.antennaId,
      tenantId: editingRow.tenantId,
    };

    try {
      const result = await updateCamera(
        `${API_ENDPOINT}/cameras/${editingRow.id}`,
        token,
        updatedCameraData,
      );
      if (result) {
        refreshCameraData();
      }
    } catch (error) {
      console.error('カメラの更新中にエラーが発生しました', error);
    }

    handleDialogClose();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'black',
                '& th': { color: 'white', fontWeight: 'bold' },
              }}
            >
              <TableCell sx={{ color: 'white' }}>カメラID</TableCell>
              <TableCell sx={{ color: 'white' }}>アンテナID</TableCell>
              <TableCell sx={{ color: 'white' }}>名称</TableCell>
              <TableCell sx={{ color: 'white' }}>説明</TableCell>
              <TableCell sx={{ color: 'white' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  {row.cameraSid}
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {row.antennaId}
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {row.name}
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {row.description}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => handleEditClick(row)}
                  >
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={editDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>カメラの編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="新しい名称"
            type="text"
            fullWidth
            variant="standard"
            value={newEditName}
            onChange={(e) => setNewEditName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="新しい説明"
            type="text"
            fullWidth
            variant="standard"
            value={newEditDescription}
            onChange={(e) => setNewEditDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDialogClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CamerasTable;
