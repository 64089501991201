import React from 'react';
import Layout from '../../components/common/Layout';
import UserAccountTable from '../../components/dataDisplay/UserAccountTable';

const UserAccountPage: React.FC = () => {
  return (
    <Layout>
      <UserAccountTable />
    </Layout>
  );
};

export default UserAccountPage;
