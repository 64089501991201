import React, { useState } from 'react';
import { Box, Typography, Button, Grid, TextField, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useCreatePasswordResetTokenData from '../../../hooks/useCreatePasswordResetTokenData';

const ResetPasswordRequest: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';
  const { createPasswordResetToken } = useCreatePasswordResetTokenData();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleResetRequest = async () => {
    try {
      const url = `${API_ENDPOINT}/auth/request_reset_password`;
      const result = await createPasswordResetToken(url, email);
      console.log(result);
      setMessage('パスワードリセットリンクを送信しました。');
      setError('');
      navigate('/');
    } catch (err) {
      setError('メールアドレスが見つかりません。');
      setMessage('');
    }
  };

  return (
    <Box sx={{ padding: 4, background: 'white', overflow: 'auto' }}>
      <Grid container spacing={2} justifyContent="center" sx={{ padding: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            パスワードの再設定
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            登録したメールアドレスを入力してください。
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="メールアドレス"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleResetRequest}
            sx={{ marginRight: 2 }}
          >
            リセットリンクを送信
          </Button>
          <Button variant="contained" onClick={() => navigate('/')}>
            ログイン画面へ
          </Button>
        </Grid>
        {message && (
          <Grid item xs={12}>
            <Alert severity="success">{message}</Alert>
          </Grid>
        )}
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ResetPasswordRequest;
