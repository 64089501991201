import React, { useState } from 'react';
import {
  Box,
  Container,
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../Header';
import NavigationBar from '../NavigationBar';
import Footer from '../Footer';
import styles from './Layout.module.css';

interface LayoutProps {
  children: React.ReactNode;
  onCameraSelect?: (cameraId: string | null) => void;
}

const Layout: React.FC<LayoutProps> = ({ children, onCameraSelect }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  return (
    <>
      <Box component="header" className={styles.header}>
        <Header />
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={styles.mobileMenuButton}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Box>
      <Drawer
        variant="temporary"
        open={mobileDrawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <NavigationBar onCameraSelect={onCameraSelect} />
      </Drawer>
      <Container className={styles.layoutContainer}>
        {!isMobile && (
          <Box component="aside" className={styles.sidebar}>
            <NavigationBar onCameraSelect={onCameraSelect} />
          </Box>
        )}
        <Box component="main" className={styles.mainContent}>
          {children}
        </Box>
      </Container>
      <Box component="footer" className={styles.footer}>
        <Footer />
      </Box>
    </>
  );
};

export default Layout;
