import React from 'react';
import Layout from '../../components/common/Layout';
import TenantAccountTable from '../../components/dataDisplay/TenantAccountTable';

const TenantAccountPage: React.FC = () => {
  return (
    <Layout>
      <TenantAccountTable />
    </Layout>
  );
};

export default TenantAccountPage;
