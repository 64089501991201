import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { userIdState } from '../../../state/user/userState';
import CircularProgress from '@mui/material/CircularProgress';
import useFetchUserData from '../../../hooks/useFetchUserData';
import useUpdateUserData from '../../../hooks/useUpdateUserData';

const UserAccountTable: React.FC = () => {
  const [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
  const [editEmailDialogOpen, setEditEmailDialogOpen] = useState(false);
  const [editImageDialogOpen, setEditImageDialogOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [refreshData, setRefreshData] = useState(false);
  const { updateUser } = useUpdateUserData();
  const token = localStorage.getItem('token') || '';
  const userId = useRecoilValue(userIdState);
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';

  const userUrl = `${API_ENDPOINT}/users/${userId}`;
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useFetchUserData(userUrl, token, refreshData);

  if (userLoading) {
    return <CircularProgress />;
  }
  if (
    userError &&
    userError.message !== 'Request failed with status code 401' &&
    userError.message !== 'Request failed with status code 404'
  ) {
    return <p>Error: {userError.message}</p>;
  }

  const refreshUserData = () => {
    setRefreshData((prev) => !prev);
  };

  const handleEditNameClick = () => {
    setNewName(userData?.user.name || '');
    setEditNameDialogOpen(true);
  };

  const handleNameDialogClose = () => {
    setEditNameDialogOpen(false);
  };

  const handleNameConfirm = async () => {
    const formData = new FormData();
    formData.append('name', newName);
    if (userData?.user.email != null) {
      formData.append('email', userData?.user.email.toString());
    }
    if (userData?.user.planId != null) {
      formData.append('plan_id', userData?.user.planId.toString());
    }
    if (userData?.user.role != null) {
      formData.append('role', userData?.user.role.toString());
    }
    if (userData?.user.agreedPrivacyPolicy != null) {
      formData.append(
        'agreed_privacy_policy',
        userData?.user.agreedPrivacyPolicy.toString(),
      );
    }
    if (userData?.userTenant.tenantId != null) {
      formData.append('tenant_id', userData?.userTenant.tenantId.toString());
    }
    if (userData?.userTenant.description != null) {
      formData.append(
        'description',
        userData?.userTenant.description.toString(),
      );
    }

    try {
      const result = await updateUser(
        `${API_ENDPOINT}/users/${userId}`,
        token,
        formData,
      );
      if (result) {
        refreshUserData();
      }
    } catch (error) {
      console.error('ユーザーの更新中にエラーが発生しました', error);
    }

    handleNameDialogClose();
  };

  const handleEditEmailClick = () => {
    setNewEmail(userData?.user.email || '');
    setEditEmailDialogOpen(true);
  };

  const handleEmailDialogClose = () => {
    setEditEmailDialogOpen(false);
  };

  const handleEmailConfirm = async () => {
    const formData = new FormData();
    if (userData?.user.name != null) {
      formData.append('name', userData?.user.name.toString());
    }
    formData.append('email', newEmail);
    if (userData?.user.planId != null) {
      formData.append('plan_id', userData?.user.planId.toString());
    }
    if (userData?.user.role != null) {
      formData.append('role', userData?.user.role.toString());
    }
    if (userData?.user.agreedPrivacyPolicy != null) {
      formData.append(
        'agreed_privacy_policy',
        userData?.user.agreedPrivacyPolicy.toString(),
      );
    }
    if (userData?.userTenant.tenantId != null) {
      formData.append('tenant_id', userData?.userTenant.tenantId.toString());
    }
    if (userData?.userTenant.description != null) {
      formData.append(
        'description',
        userData?.userTenant.description.toString(),
      );
    }

    try {
      const result = await updateUser(
        `${API_ENDPOINT}/users/${userId}`,
        token,
        formData,
      );
      if (result) {
        refreshUserData();
      }
    } catch (error) {
      console.error('ユーザーの更新中にエラーが発生しました', error);
    }

    handleEmailDialogClose();
  };

  const handleEditImageClick = () => {
    setEditImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setEditImageDialogOpen(false);
    setImagePreview(null);
  };

  const handleImageConfirm = async () => {
    if (!imageFile) {
      return;
    }

    const formData = new FormData();
    if (userData?.user.name != null) {
      formData.append('name', userData?.user.name.toString());
    }
    if (userData?.user.email != null) {
      formData.append('email', userData?.user.email.toString());
    }
    if (userData?.user.planId != null) {
      formData.append('plan_id', userData?.user.planId.toString());
    }
    if (userData?.user.role != null) {
      formData.append('role', userData?.user.role.toString());
    }
    if (userData?.user.agreedPrivacyPolicy != null) {
      formData.append(
        'agreed_privacy_policy',
        userData?.user.agreedPrivacyPolicy.toString(),
      );
    }
    if (userData?.userTenant.tenantId != null) {
      formData.append('tenant_id', userData?.userTenant.tenantId.toString());
    }
    if (userData?.userTenant.description != null) {
      formData.append(
        'description',
        userData?.userTenant.description.toString(),
      );
    }
    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      const result = await updateUser(
        `${API_ENDPOINT}/users/${userId}`,
        token,
        formData,
      );
      if (result) {
        refreshUserData();
      }
    } catch (error) {
      console.error('ユーザーの更新中にエラーが発生しました', error);
    }

    handleImageDialogClose();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'black',
                '& th': { color: 'white', fontWeight: 'bold' },
              }}
            >
              <TableCell sx={{ color: 'white' }}></TableCell>
              <TableCell sx={{ color: 'white' }}></TableCell>
              <TableCell sx={{ color: 'white' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData && (
              <TableRow
                key="name"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  名称
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {userData.user.name}
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" onClick={handleEditNameClick}>
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            {userData && (
              <TableRow
                key="email"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  メールアドレス
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {userData.user.email}
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" onClick={handleEditEmailClick}>
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            {userData && (
              <TableRow
                key="icon"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  アイコン
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  <CardMedia
                    component="img"
                    style={{
                      width: '256px',
                      height: '256px',
                    }}
                    image={
                      userData?.user.profilePicturePath
                        ? `${API_ENDPOINT}/get-image?path=${userData.user.profilePicturePath}`
                        : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwEB/wjzYrUAAAAASUVORK5CYII='
                    }
                    alt={userData.user.name}
                  />
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" onClick={handleEditImageClick}>
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            {userData && (
              <TableRow
                key="plan"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  プラン
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {userData.user.planId}
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            {userData && (
              <TableRow
                key="role"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  権限
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {userData.user.role}
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        sx={{ marginTop: 2 }}
      >
        <Grid item>
          <Button variant="contained">アカウント削除</Button>
        </Grid>
      </Grid>
      <Dialog open={editNameDialogOpen} onClose={handleNameDialogClose}>
        <DialogTitle>名称の編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="新しい名称"
            type="text"
            fullWidth
            variant="standard"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNameDialogClose}>キャンセル</Button>
          <Button onClick={handleNameConfirm}>確定</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editEmailDialogOpen} onClose={handleEmailDialogClose}>
        <DialogTitle>メールアドレスの編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="新しいメールアドレス"
            type="text"
            fullWidth
            variant="standard"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmailDialogClose}>キャンセル</Button>
          <Button onClick={handleEmailConfirm}>確定</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editImageDialogOpen} onClose={handleImageDialogClose}>
        <DialogTitle>アイコンの編集</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <label htmlFor="icon-button-file">
                <Button variant="contained" component="span">
                  ファイル選択
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              {imagePreview ? (
                <CardMedia
                  component="img"
                  sx={{
                    width: '256px',
                    height: '256px',
                  }}
                  image={imagePreview}
                  alt="アイコンプレビュー"
                />
              ) : (
                <CardMedia
                  component="img"
                  sx={{
                    width: '256px',
                    height: '256px',
                  }}
                  image={
                    imagePreview
                      ? imagePreview
                      : userData?.user.profilePicturePath
                        ? `${API_ENDPOINT}/get-image?path=${userData?.user.profilePicturePath}`
                        : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwEB/wjzYrUAAAAASUVORK5CYII='
                  }
                  alt={imagePreview ? 'アイコンプレビュー' : '現在のアイコン'}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleImageDialogClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleImageConfirm}>
            アップロード
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserAccountTable;
