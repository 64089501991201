import React from 'react';
import Layout from '../../components/common/Layout';
import CamerasTable from '../../components/dataDisplay/CamerasTable';

const CamerasPage: React.FC = () => {
  return (
    <Layout>
      <CamerasTable />
    </Layout>
  );
};

export default CamerasPage;
