import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Card,
  CardMedia,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { userIdState } from '../../../state/user/userState';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useFetchUserData from '../../../hooks/useFetchUserData';
import useFetchImagesData from '../../../hooks/useFetchImagesData';

const DashboardTable: React.FC<{ selectedCameraId: string | null }> = ({
  selectedCameraId,
}) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token') || '';
  const userId = useRecoilValue(userIdState);
  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
    endDate: new Date(),
  });
  const [rotationAngle, setRotationAngle] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';

  const userUrl = `${API_ENDPOINT}/users/${userId}`;
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useFetchUserData(userUrl, token, false);

  const validateDateRange = (
    startDate: Date | null,
    endDate: Date | null,
  ): boolean => {
    if (startDate && endDate) {
      if (endDate < startDate) {
        setErrorMessage('EndDateはStartDateより後の日付に設定してください。');
        return false;
      } else if (
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) >
        10
      ) {
        setErrorMessage('期間は10日以下に設定してください。');
        return false;
      }
    }
    setErrorMessage(null);
    return true;
  };

  const handleStartDateChange = (date: Date | null) => {
    if (validateDateRange(date, selectedDateRange.endDate)) {
      setSelectedDateRange((prev) => ({ ...prev, startDate: date }));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (validateDateRange(selectedDateRange.startDate, date)) {
      setSelectedDateRange((prev) => ({ ...prev, endDate: date }));
    }
  };

  const url =
    userData && !errorMessage
      ? `${API_ENDPOINT}/images?camera_sid=${
          selectedCameraId || 'CH10000006'
        }&tenant_id=${userData.userTenant.tenantId || ''}&start_date=${
          (selectedDateRange.startDate &&
            selectedDateRange.startDate.toISOString()) ||
          ''
        }&end_date=${
          (selectedDateRange.endDate &&
            selectedDateRange.endDate.toISOString()) ||
          ''
        }`
      : '';

  const { data, loading, error, refetch } = useFetchImagesData(url, token, [
    userData,
    selectedCameraId,
    selectedDateRange.startDate,
    selectedDateRange.endDate,
  ]);

  const uniqueImages = data?.images
    ? [...new Map(data.images.map((item) => [item.imageUrl, item])).values()]
    : [];

  if (userLoading || loading) {
    return <CircularProgress />;
  }
  if (
    userError &&
    userError.message !== 'Request failed with status code 401' &&
    userError.message !== 'Request failed with status code 404'
  ) {
    return <p>Error: {userError.message}</p>;
  }
  if (
    error &&
    error.message !== 'Request failed with status code 401' &&
    error.message !== 'Request failed with status code 404'
  ) {
    return <p>Error: {error.message}</p>;
  }

  const handleImageSelect = (cameraId: string) => {
    navigate(`/images?cameraId=${cameraId}`);
  };

  const confirmAndRequestImage = () => {
    if (window.confirm('高解像度の画像を取得しますか？')) {
      requestImage();
    }
  };

  const requestImage = async () => {
    const imageUrl = `${API_ENDPOINT}/request-image?camera_sid=${
      selectedCameraId || 'CH10000006'
    }&resolution=high`;
    try {
      const response = await axios.get(imageUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Image requested successfully:', response.data);
    } catch (error) {
      console.error('Failed to request image:', error);
    }
  };

  const rotateImages = () => {
    setRotationAngle((prevAngle) => prevAngle + 90);
  };

  const fetchLatestImages = () => {
    const newEndDate = new Date(Date.now() + 5 * 60 * 1000);
    setSelectedDateRange((prev) => ({
      ...prev,
      endDate: newEndDate,
    }));

    setTimeout(() => {
      refetch();
    }, 0);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ marginBottom: 2 }}
        >
          <Grid item>
            <Button onClick={fetchLatestImages} variant="contained">
              更新
            </Button>
          </Grid>
          <Grid item>
            <DateTimePicker
              label="Start Date"
              value={selectedDateRange.startDate}
              onChange={handleStartDateChange}
              ampm={false}
              slots={{
                textField: (props) => <TextField {...props} size="small" />,
              }}
            />
          </Grid>
          <Grid item>
            <DateTimePicker
              label="End Date"
              value={selectedDateRange.endDate}
              onChange={handleEndDateChange}
              ampm={false}
              slots={{
                textField: (props) => <TextField {...props} size="small" />,
              }}
            />
          </Grid>
          <Grid item>
            <Button onClick={confirmAndRequestImage} variant="contained">
              高解像度画像を取得
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={rotateImages} variant="contained">
              画像を回転
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'black',
                '& th': { color: 'white', fontWeight: 'bold' },
              }}
            >
              <TableCell sx={{ color: 'white' }}>名称</TableCell>
              <TableCell sx={{ color: 'white' }}>画像</TableCell>
              <TableCell sx={{ color: 'white' }}>作成日時</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniqueImages
              .map((row) => ({
                ...row,
                createdAt: new Date(row.createdAt),
              }))
              .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
              .map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => handleImageSelect(row.cameraId)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ wordBreak: 'break-word' }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ wordBreak: 'break-word' }}>
                    <Card sx={{ boxShadow: 'none' }}>
                      <CardMedia
                        component="img"
                        height="480"
                        image={`${API_ENDPOINT}/get-image?path=${row.imageUrl}`}
                        alt={row.name}
                        style={{
                          transform: `rotate(${rotationAngle}deg)`,
                          objectFit: 'contain',
                          margin: 0,
                        }}
                      />
                    </Card>
                  </TableCell>
                  <TableCell sx={{ wordBreak: 'break-word' }}>
                    <Typography>
                      {row.createdAt
                        ? row.createdAt.toLocaleString('ja-JP', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                          })
                        : '日付不明'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DashboardTable;
