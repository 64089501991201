import React, { useState } from 'react';
import { Box, Typography, Button, Grid, TextField, Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useCreatePasswordResetData from '../../../hooks/useCreatePasswordResetData';

const NewPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';
  const { createPasswordReset } = useCreatePasswordResetData();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handlePasswordReset = async () => {
    try {
      const url = `${API_ENDPOINT}/auth/reset_password`;
      if (!token) {
        throw new Error('Valid token is required');
      }
      const result = await createPasswordReset(url, token, password);
      console.log(result);
      setMessage('パスワードがリセットされました。');
      setError('');
      navigate('/');
    } catch (err) {
      setError('リンクが無効です。');
      setMessage('');
    }
  };

  return (
    <Box sx={{ padding: 4, background: 'white', overflow: 'auto' }}>
      <Grid container spacing={2} justifyContent="center" sx={{ padding: 4 }}>
        <Typography variant="h4" gutterBottom>
          新しいパスワードを入力
        </Typography>
        <Grid item xs={12}>
          <TextField
            label="新しいパスワード"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
          />
        </Grid>
        {message && (
          <Grid item xs={12}>
            <Alert severity="success">{message}</Alert>
          </Grid>
        )}
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12} container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="contained" onClick={handlePasswordReset}>
              パスワードをリセット
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => navigate('/')}>
              ログイン画面へ
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewPassword;
