import React from 'react';
import Layout from '../../components/common/Layout';
import UsersTable from '../../components/dataDisplay/UsersTable';

const UsersPage: React.FC = () => {
  return (
    <Layout>
      <UsersTable />
    </Layout>
  );
};

export default UsersPage;
