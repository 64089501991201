import React, { useRef } from 'react';
import RegisterTenantAccountConfirm from '../../components/dataRegistration/RegisterTenantAccountConfirm';
import { useParticleAnimation } from '../../hooks/useParticleAnimation';
import { Box } from '@mui/material';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

const RegisterConfirmPage: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useParticleAnimation(canvasRef);

  return (
    <>
      <Header />
      <Box sx={{ position: 'relative', width: '100vw', height: '100vh' }}>
        <canvas
          ref={canvasRef}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            maxWidth: '90vw',
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          <RegisterTenantAccountConfirm />
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default RegisterConfirmPage;
