import { useState } from 'react';
import axios from 'axios';

const useCreatePasswordResetData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const createPasswordReset = async (
    url: string,
    token: string,
    password: string,
  ) => {
    try {
      setLoading(true);
      const response = await axios.post(url, {
        token: token,
        password: password,
      });
      return response.data;
    } catch (e) {
      setError(e as Error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { createPasswordReset, loading, error };
};

export default useCreatePasswordResetData;
