import { useState } from 'react';
import axios from 'axios';

const useUpdateRcEquipmentStatus = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const updateRcEquipmentStatus = async (url: string, token: string) => {
    try {
      setLoading(true);
      await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  return { updateRcEquipmentStatus, loading, error };
};

export default useUpdateRcEquipmentStatus;
