import { useState } from 'react';
import axios from 'axios';

const useUpdateTenantData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const updateTenant = async (
    url: string,
    token: string,
    formData: FormData,
  ) => {
    try {
      setLoading(true);
      const response = await axios.put(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (e) {
      setError(e as Error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { updateTenant, loading, error };
};

export default useUpdateTenantData;
