import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const RegisterComplete: React.FC = () => {
  const navigate = useNavigate();

  const handleGoToDashboard = () => {
    navigate('/');
  };

  return (
    <Box sx={{ padding: 4, background: 'white', overflow: 'auto' }}>
      <Grid container spacing={2} justifyContent="center" sx={{ padding: 4 }}>
        <Typography variant="h4" gutterBottom>
          登録完了
        </Typography>
        <Typography variant="body1" gutterBottom>
          登録申請が完了しました。ご利用いただきありがとうございます。翌営業日を目安にデバイスのご購入と本登録に関するメールをお送りしますので、今しばらくお待ちください。
        </Typography>
        <Grid item>
          <Button variant="contained" onClick={handleGoToDashboard}>
            ログイン画面へ
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterComplete;
