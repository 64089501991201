import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { userIdState } from '../../../state/user/userState';
import CircularProgress from '@mui/material/CircularProgress';
import useFetchUserData from '../../../hooks/useFetchUserData';
import {
  UserResponse,
  UserTenantsResponse,
  UserTenantResponse,
} from '../../../types';
import useCreateUserData from '../../../hooks/useCreateUserData';
import useUpdateUserData from '../../../hooks/useUpdateUserData';
import useDeleteUserData from '../../../hooks/useDeleteUserData';

const UsersTable: React.FC = () => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newCreateEmail, setNewCreateEmail] = useState('');
  const [newCreateName, setNewCreateName] = useState('');
  const [newCreateDescription, setNewCreateDescription] = useState('');
  const [newCreateRole, setNewCreateRole] = useState(1);
  const [newEditName, setNewEditName] = useState('');
  const [newEditDescription, setNewEditDescription] = useState('');
  const [refreshData, setRefreshData] = useState(false);
  const { createUser } = useCreateUserData();
  const { updateUser } = useUpdateUserData();
  const { deleteUser } = useDeleteUserData();
  const [editingRow, setEditingRow] = useState<UserResponse | null>(null);
  const [deletingRow, setDeletingRow] = useState<UserResponse | null>(null);
  const token = localStorage.getItem('token') || '';
  const userId = useRecoilValue(userIdState);
  const [usersData, setUsersData] = useState<UserResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';

  const userUrl = `${API_ENDPOINT}/users/${userId}`;
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useFetchUserData(userUrl, token, refreshData);

  const userTenantsUrl = userData
    ? `${API_ENDPOINT}/users/tenant/${userData.userTenant.tenantId}`
    : '';

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        if (userTenantsUrl) {
          const userTenantsResponse = await axios.get<UserTenantsResponse>(
            userTenantsUrl,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          const usersUrls = userTenantsResponse.data.userTenants.map(
            (userTenant: UserTenantResponse) =>
              `${API_ENDPOINT}/users/${userTenant.userId}`,
          );
          const usersResponses = await Promise.all(
            usersUrls.map((url: string) =>
              axios.get<UserResponse>(url, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }),
            ),
          );
          setUsersData(usersResponses.map((response) => response.data));
        }
      } catch (e) {
        console.error('エラーが発生しました：', e);
        setError(e as Error);
      }
      setLoading(false);
    };
    if (userId && token) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [API_ENDPOINT, userId, userTenantsUrl, token, refreshData]);

  if (userLoading || loading) {
    return <CircularProgress />;
  }
  if (
    userError &&
    userError.message !== 'Request failed with status code 401' &&
    userError.message !== 'Request failed with status code 404'
  ) {
    return <p>Error: {userError.message}</p>;
  }
  if (
    error &&
    error.message !== 'Request failed with status code 401' &&
    error.message !== 'Request failed with status code 404'
  ) {
    return <p>Error: {error.message}</p>;
  }

  const filteredRows = usersData ? usersData : [];

  const rows: UserResponse[] = filteredRows?.map((userData) => ({
    user: {
      id: userData.user.id,
      email: userData.user.email,
      passwordHash: '',
      name: userData.user.name,
      profilePicturePath: userData.user.profilePicturePath,
      planId: userData.user.planId,
      agreedPrivacyPolicy: userData.user.agreedPrivacyPolicy,
      role: userData.user.role,
    },
    userTenant: {
      id: '',
      userId: userData.userTenant.id,
      tenantId: userData.userTenant.tenantId,
      description: userData.userTenant.description,
      role: userData.user.role,
    },
  }));

  const refreshUserData = () => {
    setRefreshData((prev) => !prev);
  };

  const handleCreateClick = () => {
    setNewCreateEmail('');
    setNewCreateName('');
    setNewCreateRole(1);
    setNewCreateDescription('');
    setCreateDialogOpen(true);
  };

  const handleCreateDialogClose = () => {
    setCreateDialogOpen(false);
  };

  const handleCreateConfirm = async () => {
    if (!userData) return;

    const formData = new FormData();
    formData.append('name', newCreateName);
    formData.append('email', newCreateEmail);
    formData.append('plan_id', userData.user.planId.toString());
    formData.append('role', newCreateRole.toString());
    formData.append('agreed_privacy_policy', false.toString());
    formData.append('tenant_id', userData.userTenant.tenantId.toString());
    formData.append('description', newCreateDescription);

    try {
      const result = await createUser(`${API_ENDPOINT}/users`, formData);
      if (result) {
        refreshUserData();
      }
    } catch (error) {
      console.error('ユーザーの作成中にエラーが発生しました', error);
    }

    handleCreateDialogClose();
  };

  const handleEditClick = (row: UserResponse) => {
    setEditingRow(row);
    setNewEditName(row.user.name);
    setNewEditDescription(row.userTenant.description);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditConfirm = async () => {
    if (!editingRow) return;

    const formData = new FormData();
    formData.append('name', newEditName);
    formData.append('email', editingRow.user.email.toString());
    formData.append('plan_id', editingRow.user.planId.toString());
    formData.append('role', editingRow.user.role.toString());
    formData.append(
      'agreed_privacy_policy',
      editingRow.user.agreedPrivacyPolicy.toString(),
    );
    formData.append('tenant_id', editingRow.userTenant.tenantId.toString());
    formData.append('description', newEditDescription);

    try {
      const result = await updateUser(
        `${API_ENDPOINT}/users/${editingRow.user.id}`,
        token,
        formData,
      );
      if (result) {
        refreshUserData();
      }
    } catch (error) {
      console.error('ユーザーの更新中にエラーが発生しました', error);
    }

    handleEditDialogClose();
  };

  const handleDeleteClick = (row: UserResponse) => {
    setDeletingRow(row);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    if (!deletingRow) return;

    try {
      await deleteUser(`${API_ENDPOINT}/users/${deletingRow.user.id}`, token);
      refreshUserData();
    } catch (error) {
      console.error('ユーザーの削除中にエラーが発生しました', error);
    }

    handleDeleteDialogClose();
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        sx={{ marginBottom: 2 }}
      >
        <Grid item>
          <Button variant="contained" onClick={handleCreateClick}>
            新規作成
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'black',
                '& th': { color: 'white', fontWeight: 'bold' },
              }}
            >
              <TableCell sx={{ color: 'white' }}>ユーザー名</TableCell>
              <TableCell sx={{ color: 'white' }}>メールアドレス</TableCell>
              <TableCell sx={{ color: 'white' }}>説明</TableCell>
              <TableCell sx={{ color: 'white' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.user.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  {row.user.name}
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {row.user.email}
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {row.userTenant.description}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => handleEditClick(row)}
                    sx={{ marginRight: 2 }}
                  >
                    編集
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleDeleteClick(row)}
                  >
                    削除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={createDialogOpen} onClose={handleCreateDialogClose}>
        <DialogTitle>ユーザーの新規作成</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="メールアドレス"
            type="text"
            fullWidth
            variant="standard"
            value={newCreateEmail}
            onChange={(e) => setNewCreateEmail(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="名称"
            type="text"
            fullWidth
            variant="standard"
            value={newCreateName}
            onChange={(e) => setNewCreateName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="role"
            label="権限"
            type="text"
            fullWidth
            variant="standard"
            value={newCreateRole}
            onChange={(e) => setNewCreateDescription(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="説明"
            type="text"
            fullWidth
            variant="standard"
            value={newCreateDescription}
            onChange={(e) => setNewCreateDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCreateDialogClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleCreateConfirm}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>ユーザーの編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="名称"
            type="text"
            fullWidth
            variant="standard"
            value={newEditName}
            onChange={(e) => setNewEditName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="新しい説明"
            type="text"
            fullWidth
            variant="standard"
            value={newEditDescription}
            onChange={(e) => setNewEditDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleEditDialogClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleEditConfirm}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>ユーザーの削除</DialogTitle>
        <DialogContent>
          {deletingRow?.user.email} を削除しますか？
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDeleteDialogClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleDeleteConfirm}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersTable;
