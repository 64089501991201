import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { TextField, Button, Paper, Box, Link } from '@mui/material';
import {
  isLoggedInState,
  userIdState,
  userPlanIdState,
} from '../../state/user/userState';
import axios from 'axios';

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loggedIn, setLoggedIn] = useRecoilState(isLoggedInState);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  // const [agreedPrivacyPolicy, setAgreedPrivacyPolicy] = useState(false);
  // const [agreedTermsOfService, setAgreedTermsOfService] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [userId, setUserId] = useRecoilState<string>(userIdState);
  const [userPlan, setUserPlan] = useRecoilState<number>(userPlanIdState);
  const [privacyPolicyError, setPrivacyPolicyError] = useState('');
  const changeLoggedInState = (state: boolean) => setLoggedIn(state);
  const changeUserIdState = (state: string) => setUserId(state);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const API_ENDPOINT =
      process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';

    try {
      const val =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.trim() === '') {
        setEmailError('メールアドレスが必要です');
        setErrorMessage('');
        setPasswordError('');
      } else if (!val.test(email.trim())) {
        setEmailError('無効なメールアドレスです');
        setErrorMessage('');
        setPasswordError('');
      } else if (password.trim() === '') {
        setPasswordError('パスワードが必要です');
        setEmailError('');
        setErrorMessage('');
      } else if (password.trim().length < 6) {
        setPasswordError('パスワードは6文字以上である必要があります');
        setEmailError('');
        setErrorMessage('');
        console.log(loggedIn);
      } else {
        await axios
          .post(
            `${API_ENDPOINT}/auth/login`,
            {
              email: email,
              password: password,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
          .then(function (response) {
            if (response.status === 200) {
              changeLoggedInState(true);
              changeUserIdState(response.data.user.id);
              setUserPlan(response.data.user.planId);
              localStorage.setItem('token', response.data.token);
              if (response.data.user.agreedPrivacyPolicy) {
                const token = response.data.token;
                console.log('Login Successful! Token:', token);
                navigate('/dashboard');
              } else {
                setPrivacyPolicyError('');
                setUserId(response.data.user.id);
                setOpenPrivacyPolicy(true);
              }
            } else {
              setErrorMessage('エラーが発生しました。');
              setEmailError('');
              setPasswordError('');
              console.log(emailError);
              console.log(passwordError);
              console.log(errorMessage);
              console.log(userPlan);
              console.log(userId);
              console.log(openPrivacyPolicy);
              console.log(privacyPolicyError);
            }
          });
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Paper
        elevation={3}
        sx={{
          padding: 2,
          width: '100%',
          margin: 'auto',
          background: 'white',
          zIndex: 1,
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            sx={{ mt: 2 }}
          >
            {isLoading ? 'Loading...' : 'ログイン'}
          </Button>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
              mt: 2,
            }}
          >
            <Link component={RouterLink} to="/register">
              新規会員登録
            </Link>
            <Link component={RouterLink} to="/reset-password">
              パスワードをお忘れの方はこちら
            </Link>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginForm;
