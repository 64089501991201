import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { userIdState } from '../../../state/user/userState';
import CircularProgress from '@mui/material/CircularProgress';
import useFetchUserData from '../../../hooks/useFetchUserData';
import useFetchTenantData from '../../../hooks/useFetchTenantData';
import useUpdateTenantData from '../../../hooks/useUpdateTenantData';

const TenantAccountTable: React.FC = () => {
  const [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
  const [editPlanDialogOpen, setEditPlanDialogOpen] = useState(false);
  const [editAddressDialogOpen, setEditAddressDialogOpen] = useState(false);
  const [editShippingAddressDialogOpen, setEditShippingAddressDialogOpen] =
    useState(false);
  const [editPhoneNumberDialogOpen, setEditPhoneNumberDialogOpen] =
    useState(false);
  const [editImageDialogOpen, setEditImageDialogOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [newPlan, setNewPlan] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [newShippingAddress, setNewShippingAddress] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [refreshData, setRefreshData] = useState(false);
  const { updateTenant } = useUpdateTenantData();
  const token = localStorage.getItem('token') || '';
  const userId = useRecoilValue(userIdState);
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';

  const userUrl = `${API_ENDPOINT}/users/${userId}`;
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useFetchUserData(userUrl, token, false);

  const userTenantsUrl = userData
    ? `${API_ENDPOINT}/tenants/${userData.userTenant.tenantId}`
    : '';
  const { data, loading, error } = useFetchTenantData(
    userTenantsUrl,
    token,
    refreshData,
  );

  if (userLoading || loading) {
    return <CircularProgress />;
  }
  if (userError) {
    return <p>Error: {userError.message}</p>;
  }
  if (
    error &&
    error.message !== 'Request failed with status code 401' &&
    error.message !== 'Request failed with status code 404'
  ) {
    return <p>Error: {error.message}</p>;
  }

  const refreshTenantData = () => {
    setRefreshData((prev) => !prev);
  };

  const handleEditNameClick = () => {
    setNewName(data?.name || '');
    setEditNameDialogOpen(true);
  };

  const handleNameDialogClose = () => {
    setEditNameDialogOpen(false);
  };

  const handleNameConfirm = async () => {
    const formData = new FormData();
    formData.append('name', newName);
    if (data?.planId != null) {
      formData.append('plan_id', data.planId.toString());
    }
    if (data?.address != null) {
      formData.append('address', data.address.toString());
    }
    if (data?.shippingAddress != null) {
      formData.append('shipping_address', data.shippingAddress.toString());
    }
    if (data?.phoneNumber != null) {
      formData.append('phone_number', data.phoneNumber.toString());
    }

    try {
      const result = await updateTenant(
        `${API_ENDPOINT}/tenants/${userData?.userTenant.tenantId}`,
        token,
        formData,
      );
      if (result) {
        refreshTenantData();
      }
    } catch (error) {
      console.error('テナントの更新中にエラーが発生しました', error);
    }

    handleNameDialogClose();
  };

  const handleEditPlanClick = () => {
    setNewPlan(data?.planId.toString() || '');
    setEditPlanDialogOpen(true);
  };

  const handlePlanDialogClose = () => {
    setEditPlanDialogOpen(false);
  };

  const handlePlanConfirm = async () => {
    const formData = new FormData();
    if (data?.name != null) {
      formData.append('name', data.name.toString());
    }
    formData.append('plan_id', newPlan);
    if (data?.address != null) {
      formData.append('address', data.address.toString());
    }
    if (data?.shippingAddress != null) {
      formData.append('shipping_address', data.shippingAddress.toString());
    }
    if (data?.phoneNumber != null) {
      formData.append('phone_aumber', data.phoneNumber.toString());
    }

    try {
      const result = await updateTenant(
        `${API_ENDPOINT}/tenants/${userData?.userTenant.tenantId}`,
        token,
        formData,
      );
      if (result) {
        refreshTenantData();
      }
    } catch (error) {
      console.error('テナントの更新中にエラーが発生しました', error);
    }
    handlePlanDialogClose();
  };

  const handleEditAddressClick = () => {
    setNewAddress(data?.address || '');
    setEditAddressDialogOpen(true);
  };

  const handleAddressDialogClose = () => {
    setEditAddressDialogOpen(false);
  };

  const handleAddressConfirm = async () => {
    const formData = new FormData();
    if (data?.name != null) {
      formData.append('name', data.name.toString());
    }
    if (data?.planId != null) {
      formData.append('plan_id', data.planId.toString());
    }
    formData.append('address', newAddress);
    if (data?.shippingAddress != null) {
      formData.append('shipping_address', data.shippingAddress.toString());
    }
    if (data?.phoneNumber != null) {
      formData.append('phone_number', data.phoneNumber.toString());
    }

    try {
      const result = await updateTenant(
        `${API_ENDPOINT}/tenants/${userData?.userTenant.tenantId}`,
        token,
        formData,
      );
      if (result) {
        refreshTenantData();
      }
    } catch (error) {
      console.error('テナントの更新中にエラーが発生しました', error);
    }
    handleAddressDialogClose();
  };

  const handleEditShippingAddressClick = () => {
    setNewShippingAddress(data?.shippingAddress || '');
    setEditShippingAddressDialogOpen(true);
  };

  const handleShippingAddressDialogClose = () => {
    setEditShippingAddressDialogOpen(false);
  };

  const handleShippingAddressConfirm = async () => {
    const formData = new FormData();
    if (data?.name != null) {
      formData.append('name', data.name.toString());
    }
    if (data?.planId != null) {
      formData.append('plan_id', data.planId.toString());
    }
    if (data?.address != null) {
      formData.append('address', data.address.toString());
    }
    formData.append('shipping_address', newShippingAddress);
    if (data?.phoneNumber != null) {
      formData.append('phone_number', data.phoneNumber.toString());
    }

    try {
      const result = await updateTenant(
        `${API_ENDPOINT}/tenants/${userData?.userTenant.tenantId}`,
        token,
        formData,
      );
      if (result) {
        refreshTenantData();
      }
    } catch (error) {
      console.error('テナントの更新中にエラーが発生しました', error);
    }
    handleShippingAddressDialogClose();
  };

  const handleEditPhoneNumberClick = () => {
    setNewPhoneNumber(data?.phoneNumber || '');
    setEditPhoneNumberDialogOpen(true);
  };

  const handlePhoneNumberDialogClose = () => {
    setEditPhoneNumberDialogOpen(false);
  };

  const handlePhoneNumberConfirm = async () => {
    const formData = new FormData();
    if (data?.name != null) {
      formData.append('name', data.name.toString());
    }
    if (data?.planId != null) {
      formData.append('plan_id', data.planId.toString());
    }
    if (data?.address != null) {
      formData.append('address', data.address.toString());
    }
    if (data?.shippingAddress != null) {
      formData.append('shipping_address', data.shippingAddress.toString());
    }
    formData.append('phone_number', newPhoneNumber);

    try {
      const result = await updateTenant(
        `${API_ENDPOINT}/tenants/${userData?.userTenant.tenantId}`,
        token,
        formData,
      );
      if (result) {
        refreshTenantData();
      }
    } catch (error) {
      console.error('テナントの更新中にエラーが発生しました', error);
    }
    handlePhoneNumberDialogClose();
  };

  const handleEditImageClick = () => {
    setEditImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setEditImageDialogOpen(false);
    setImagePreview(null);
  };

  const handleImageConfirm = async () => {
    if (!imageFile) {
      return;
    }

    const formData = new FormData();
    if (data?.name != null) {
      formData.append('name', data.name.toString());
    }
    if (data?.planId != null) {
      formData.append('plan_id', data.planId.toString());
    }
    if (data?.address != null) {
      formData.append('address', data.address.toString());
    }
    if (data?.shippingAddress != null) {
      formData.append('shipping_address', data.shippingAddress.toString());
    }
    if (data?.phoneNumber != null) {
      formData.append('phone_number', data.phoneNumber.toString());
    }
    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      const result = await updateTenant(
        `${API_ENDPOINT}/tenants/${userData?.userTenant.tenantId}`,
        token,
        formData,
      );
      if (result) {
        refreshTenantData();
      }
    } catch (error) {
      console.error('テナントの更新中にエラーが発生しました', error);
    }

    handleImageDialogClose();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'black',
                '& th': { color: 'white', fontWeight: 'bold' },
              }}
            >
              <TableCell sx={{ color: 'white' }}></TableCell>
              <TableCell sx={{ color: 'white' }}></TableCell>
              <TableCell sx={{ color: 'white' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && (
              <TableRow
                key="name"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  名称
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {data.name}
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" onClick={handleEditNameClick}>
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            {data && (
              <TableRow
                key="plan"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  プラン
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {data.planId}
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" onClick={handleEditPlanClick}>
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            {data && (
              <TableRow
                key="address"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  住所
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {data.address}
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" onClick={handleEditAddressClick}>
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            {data && (
              <TableRow
                key="shippingAddress"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  配送先住所
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {data.shippingAddress}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={handleEditShippingAddressClick}
                  >
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            {data && (
              <TableRow
                key="phoneNumber"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  電話番号
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  {data.phoneNumber}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={handleEditPhoneNumberClick}
                  >
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            {data && (
              <TableRow
                key="icon"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word' }}
                >
                  アイコン
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word' }}>
                  <CardMedia
                    component="img"
                    style={{
                      width: '256px',
                      height: '256px',
                    }}
                    image={
                      data?.profilePicturePath
                        ? `${API_ENDPOINT}/get-image?path=${data.profilePicturePath}`
                        : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwEB/wjzYrUAAAAASUVORK5CYII='
                    }
                    alt={data.name}
                  />
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" onClick={handleEditImageClick}>
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        sx={{ marginTop: 2 }}
      >
        <Grid item>
          <Button variant="contained">組織を削除する</Button>
        </Grid>
      </Grid>
      <Dialog open={editNameDialogOpen} onClose={handleNameDialogClose}>
        <DialogTitle>名称の編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="新しい名称"
            type="text"
            fullWidth
            variant="standard"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNameDialogClose}>キャンセル</Button>
          <Button onClick={handleNameConfirm}>確定</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editPlanDialogOpen} onClose={handlePlanDialogClose}>
        <DialogTitle>プランの編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="plan"
            label="新しいプラン"
            type="text"
            fullWidth
            variant="standard"
            value={newPlan}
            onChange={(e) => setNewPlan(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handlePlanDialogClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handlePlanConfirm}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editAddressDialogOpen} onClose={handleAddressDialogClose}>
        <DialogTitle>住所の編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="address"
            label="新しい住所"
            type="text"
            fullWidth
            variant="standard"
            value={newAddress}
            onChange={(e) => setNewAddress(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleAddressDialogClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleAddressConfirm}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editShippingAddressDialogOpen}
        onClose={handleShippingAddressDialogClose}
      >
        <DialogTitle>配送先住所の編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="shippingAddress"
            label="新しい配送先住所"
            type="text"
            fullWidth
            variant="standard"
            value={newShippingAddress}
            onChange={(e) => setNewShippingAddress(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleShippingAddressDialogClose}
          >
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleShippingAddressConfirm}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editPhoneNumberDialogOpen}
        onClose={handlePhoneNumberDialogClose}
      >
        <DialogTitle>電話番号の編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="phoneNumber"
            label="新しい電話番号"
            type="text"
            fullWidth
            variant="standard"
            value={newPhoneNumber}
            onChange={(e) => setNewPhoneNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handlePhoneNumberDialogClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handlePhoneNumberConfirm}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editImageDialogOpen} onClose={handleImageDialogClose}>
        <DialogTitle>アイコンの編集</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <label htmlFor="icon-button-file">
                <Button variant="contained" component="span">
                  ファイル選択
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              {imagePreview ? (
                <CardMedia
                  component="img"
                  sx={{
                    width: '256px',
                    height: '256px',
                  }}
                  image={imagePreview}
                  alt="アイコンプレビュー"
                />
              ) : (
                <CardMedia
                  component="img"
                  sx={{
                    width: '256px',
                    height: '256px',
                  }}
                  image={
                    imagePreview
                      ? imagePreview
                      : data?.profilePicturePath
                        ? `${API_ENDPOINT}/get-image?path=${data.profilePicturePath}`
                        : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwEB/wjzYrUAAAAASUVORK5CYII='
                  }
                  alt={imagePreview ? 'アイコンプレビュー' : '現在のアイコン'}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleImageDialogClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleImageConfirm}>
            アップロード
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TenantAccountTable;
