import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { isLoggedInState, userIdState } from '../../../state/user/userState';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import GenplsIcon from '../../../assets/genpls_logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import useFetchTenantData from '../../../hooks/useFetchTenantData';
import useFetchUserData from '../../../hooks/useFetchUserData';

const Header: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem('token') || '';
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const setIsLoggedIn = useSetRecoilState(isLoggedInState);
  const userId = useRecoilValue(userIdState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const resetIsLoggedIn = useResetRecoilState(isLoggedInState);
  const resetUserId = useResetRecoilState(userIdState);

  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';
  const userUrl = `${API_ENDPOINT}/users/${userId}`;
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useFetchUserData(userUrl, token, isLoggedIn);

  const tenantUrl = userData
    ? `${API_ENDPOINT}/tenants/${userData.userTenant.tenantId}`
    : '';
  const {
    data: tenantData,
    loading: tenantLoading,
    error: tenantError,
  } = useFetchTenantData(tenantUrl, token, isLoggedIn);

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    } else if (
      location.pathname === '/register' ||
      location.pathname === '/register-confirm' ||
      location.pathname === '/register-complete' ||
      location.pathname === '/reset-password' ||
      location.pathname === '/new-password' ||
      location.pathname.startsWith('/new-password/')
    ) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(false);
      navigate('/');
    }
  }, [token, setIsLoggedIn]);

  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  const handleTenantIconClick = () => {
    navigate('/tenant-account');
  };

  const handleUserIconClick = () => {
    navigate('/user-account');
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleImagesClick = () => {
    navigate('/images');
  };

  const handleUsersClick = () => {
    navigate('/users');
  };

  const handleCamerasClick = () => {
    navigate('/cameras');
  };

  const handleRcEquipmentsClick = () => {
    navigate('/rc-equipments');
  };

  const handleLogoutClick = async () => {
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.status === 200) {
        resetIsLoggedIn();
        resetUserId();
        localStorage.removeItem('token');
        navigate('/');
      } else {
        console.error('Logout failed', response.status);
      }
    } catch (error) {
      console.error('An error occurred during logout', error);
    }
  };

  if (!isLoggedIn) {
    return (
      <AppBar position="fixed" sx={{ bgcolor: 'primary.main' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleLogoClick}
            sx={{ bgcolor: 'white' }}
          >
            <img
              src={GenplsIcon}
              alt="Genpls Logo"
              style={{ width: '24px', height: '24px' }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  } else {
    if (userLoading || tenantLoading) return <CircularProgress />;
    if (
      userError &&
      userError.message !== 'Request failed with status code 401' &&
      userError.message !== 'Request failed with status code 404'
    ) {
      return <p>Error: {userError.message}</p>;
    }
    if (
      tenantError &&
      tenantError.message !== 'Request failed with status code 401' &&
      tenantError.message !== 'Request failed with status code 404'
    ) {
      return <p>Error: {tenantError.message}</p>;
    }

    return (
      <AppBar position="fixed" sx={{ bgcolor: 'primary.main' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleLogoClick}
            sx={{ bgcolor: 'white' }}
          >
            <img
              src={GenplsIcon}
              alt="Genpls Logo"
              style={{ width: '24px', height: '24px' }}
            />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} display="flex" justifyContent="flex-end">
            <IconButton onClick={handleTenantIconClick}>
              <Avatar
                src={
                  tenantData
                    ? `${API_ENDPOINT}/get-image?path=${encodeURIComponent(
                        tenantData.profilePicturePath,
                      )}`
                    : undefined
                }
                alt="Tenant Icon"
              />
            </IconButton>
            <IconButton onClick={handleUserIconClick}>
              <Avatar
                src={
                  userData
                    ? `${API_ENDPOINT}/get-image?path=${encodeURIComponent(
                        userData.user.profilePicturePath,
                      )}`
                    : undefined
                }
                alt="User Icon"
              />
            </IconButton>
            <IconButton edge="end" color="inherit" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="settings-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleDashboardClick}>ダッシュボード</MenuItem>
              <MenuItem onClick={handleImagesClick}>画像一覧</MenuItem>
              <MenuItem onClick={handleUsersClick}>ユーザー一覧</MenuItem>
              <MenuItem onClick={handleCamerasClick}>カメラ一覧</MenuItem>
              <MenuItem onClick={handleRcEquipmentsClick}>
                遠隔制御機器一覧
              </MenuItem>
              <MenuItem onClick={handleLogoutClick}>ログアウト</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
};

export default Header;
