import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import styles from './ImageCarousel.module.css';
import { ImagesResponse } from '../../../types';
import { Box, CardMedia, Typography } from '@mui/material';

const ImageCarousel: React.FC<ImagesResponse> = ({ images, rotationAngle }) => {
  const mainSlider = useRef<Slider | null>(null);
  const navSlider = useRef<Slider | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const mainSettings = {
    asNavFor: navSlider.current ? navSlider.current : undefined,
  };

  const navSettings = {
    beforeChange: (current: number, next: number) => setActiveSlide(next),
    slidesToShow: 10,
    swipeToSlide: true,
    focusOnSelect: true,
    infinite: false,
    asNavFor: mainSlider.current ? mainSlider.current : undefined,
  };

  return (
    <>
      <Slider ref={mainSlider} {...mainSettings}>
        {images.map((image) => (
          <Box
            key={image.id}
            className={styles.imageContainer}
            sx={{
              //width: '100%',
              //height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
            }}
          >
            <Typography variant="subtitle1">{image.name}</Typography>
            <Typography variant="subtitle1">
              {image.createdAt.toString()}
            </Typography>
            <Box
              sx={{
                width: '100%',
                maxHeight: '1600px',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <CardMedia
                component="img"
                image={image.imageUrl}
                alt={image.name}
                className={styles.carouselImage}
                sx={{ objectFit: 'contain' }}
                style={{
                  transform: `rotate(${rotationAngle}deg)`,
                }}
              />
            </Box>
          </Box>
        ))}
      </Slider>
      <Slider ref={navSlider} {...navSettings}>
        {images.map((image, index) => (
          <Box
            key={image.id}
            className={styles.imageContainer}
            sx={{ width: '100px', height: '50px' }}
          >
            <CardMedia
              component="img"
              image={image.imageUrl}
              alt={image.name}
              className={`${styles.carouselImage} ${
                index === activeSlide ? styles.activeImage : ''
              }`}
              style={{
                height: '50px',
                transform: `rotate(${rotationAngle}deg)`,
              }}
            />
          </Box>
        ))}
      </Slider>
    </>
  );
};

export default ImageCarousel;
