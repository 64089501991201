import { useState, useEffect } from 'react';
import axios from 'axios';
import { TenantResponse } from '../types';

const useFetchTenantData = (
  url: string,
  token: string,
  refreshData: boolean,
) => {
  const [data, setData] = useState<TenantResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, [url, token, refreshData]);

  return { data, loading, error };
};

export default useFetchTenantData;
