import { useState } from 'react';
import axios from 'axios';

const useCreateTenantData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const createTenant = async (url: string, formData: FormData) => {
    try {
      setLoading(true);
      const response = await axios.post(url, formData);
      return response.data;
    } catch (e) {
      setError(e as Error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { createTenant, loading, error };
};

export default useCreateTenantData;
