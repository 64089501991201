import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userIdState } from '../../state/user/userState';
import ImageCarousel from '../../components/dataDisplay/ImageCarousel';
import useFetchUserData from '../../hooks/useFetchUserData';
import useFetchImagesData from '../../hooks/useFetchImagesData';
import { ImageData } from '../../types';
import Layout from '../../components/common/Layout';
import CircularProgress from '@mui/material/CircularProgress';

import { TextField, Grid, Button } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const ImagesPage: React.FC = () => {
  const token = localStorage.getItem('token') || '';
  const userId = useRecoilValue(userIdState);
  const [searchParams] = useSearchParams();
  const selectedCameraId = searchParams.get('cameraId');
  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
    endDate: new Date(),
  });
  const [rotationAngle, setRotationAngle] = useState(0);
  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';

  const userUrl = `${API_ENDPOINT}/users/${userId}`;
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useFetchUserData(userUrl, token, false);

  const url = userData
    ? `${API_ENDPOINT}/images?camera_sid=${
        selectedCameraId || 'CH10000006'
      }&tenant_id=${userData.userTenant.tenantId || ''}&start_date=${
        (selectedDateRange.startDate &&
          selectedDateRange.startDate.toISOString()) ||
        ''
      }&end_date=${
        (selectedDateRange.endDate &&
          selectedDateRange.endDate.toISOString()) ||
        ''
      }`
    : '';

  const { data, loading, error } = useFetchImagesData(url, token, [
    userData,
    selectedCameraId,
    selectedDateRange.startDate,
    selectedDateRange.endDate,
  ]);
  if (userLoading || loading) {
    return <CircularProgress />;
  }
  if (userError) return <p>Error</p>;
  if (
    error &&
    error.message !== 'Request failed with status code 401' &&
    error.message !== 'Request failed with status code 404'
  ) {
    return <p>Error</p>;
  }

  const images: ImageData[] = data?.images
    ? data.images
        .filter(
          (imgData) =>
            selectedCameraId === null || imgData.cameraId === selectedCameraId,
        )
        .map((imgData) => ({
          id: imgData.id,
          name: imgData.name,
          cameraId: imgData.cameraId,
          tenantId: imgData.tenantId,
          imageUrl: `${API_ENDPOINT}/get-image?path=${imgData.imageUrl}`,
          createdAt: new Date(imgData.createdAt),
        }))
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
    : [];

  const rotateImages = () => {
    setRotationAngle((prevAngle) => prevAngle + 90);
  };

  return (
    <Layout>
      {data && (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <DateTimePicker
                  label="Start Date"
                  value={selectedDateRange.startDate}
                  onChange={(date: Date | null) =>
                    setSelectedDateRange((prev) => ({
                      ...prev,
                      startDate: date,
                    }))
                  }
                  ampm={false}
                  slots={{
                    textField: (props) => <TextField {...props} size="small" />,
                  }}
                />
              </Grid>
              <Grid item>
                <DateTimePicker
                  label="End Date"
                  value={selectedDateRange.endDate}
                  onChange={(date: Date | null) =>
                    setSelectedDateRange((prev) => ({ ...prev, endDate: date }))
                  }
                  ampm={false}
                  slots={{
                    textField: (props) => <TextField {...props} size="small" />,
                  }}
                />
              </Grid>
              <Grid item>
                <Button onClick={rotateImages} variant="contained">
                  画像を回転
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
          <ImageCarousel images={images} rotationAngle={rotationAngle} />
        </>
      )}
    </Layout>
  );
};

export default ImagesPage;
