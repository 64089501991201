import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const isLoggedInState = atom({
  key: 'isLoggedInState',
  default: false,
});

export const userIdState = atom({
  key: 'userIdState',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const userPlanIdState = atom({
  key: 'userPlanState',
  default: 0,
});
