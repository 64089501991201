import React, { useState } from 'react';
import Layout from '../../components/common/Layout';
import DashboardTable from '../../components/dataDisplay/DashboardTable';

const DashboardPage: React.FC = () => {
  const [selectedCameraId, setSelectedCameraId] = useState<string | null>(null);

  const handleCameraSelect = (cameraId: string | null) => {
    setSelectedCameraId(cameraId);
  };

  return (
    <Layout onCameraSelect={handleCameraSelect}>
      <DashboardTable selectedCameraId={selectedCameraId} />
    </Layout>
  );
};

export default DashboardPage;
