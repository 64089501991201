import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useFetchPlansData from '../../../hooks/useFetchPlansData';
import { PlanResponse } from '../../../types';

const RegisterTenantAccount: React.FC = () => {
  const navigate = useNavigate();
  const [newCreateEmail, setNewCreateEmail] = useState('');
  const [newCreateUserName, setNewCreateUserName] = useState('');
  const [newCreateTenantName, setNewCreateTenantName] = useState('');
  const [newCreatePlan, setNewCreatePlan] = useState('');
  const [newCreateAddress, setNewCreateAddress] = useState('');
  const [newCreateShippingAddress, setNewCreateShippingAddress] = useState('');
  const [newCreatePhoneNumber, setNewCreatePhoneNumber] = useState('');

  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';

  const { data, loading, error } = useFetchPlansData(
    `${API_ENDPOINT}/plans`,
    true,
  );

  const handleBack = () => {
    navigate('/');
  };

  const handleCreateConfirm = () => {
    const tenantData = {
      name: newCreateTenantName,
      plan_id: newCreatePlan,
      address: newCreateAddress,
      shipping_address: newCreateShippingAddress,
      phone_number: newCreatePhoneNumber,
    };

    const userData = {
      email: newCreateEmail,
      name: newCreateUserName,
      plan_id: newCreatePlan,
      role: '1',
      agreed_privacy_policy: 'true',
      description: '',
    };

    console.log(tenantData);
    console.log(userData);

    navigate('/register-confirm', { state: { tenantData, userData } });
  };

  return (
    <Box sx={{ padding: 4, background: 'white', overflow: 'auto' }}>
      <Grid container spacing={2} justifyContent="center" sx={{ padding: 4 }}>
        <TableContainer component={Grid}>
          <Table sx={{ minWidth: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'black',
                  '& th': { color: 'white', fontWeight: 'bold' },
                }}
              >
                <TableCell sx={{ color: 'white' }}></TableCell>
                <TableCell sx={{ color: 'white' }}></TableCell>
                <TableCell sx={{ color: 'white' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key="email"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word', width: '30%' }}
                >
                  メールアドレス（必須）例: example@example.com
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word', width: '70%' }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="メールアドレス"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={newCreateEmail}
                    onChange={(e) => setNewCreateEmail(e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow
                key="userName"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word', width: '30%' }}
                >
                  ユーザー名（必須）例: 和久井太郎
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word', width: '70%' }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="userName"
                    label="ユーザー名"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={newCreateUserName}
                    onChange={(e) => setNewCreateUserName(e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow
                key="tenantName"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word', width: '30%' }}
                >
                  組織名（必須）例: Genpls
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word', width: '70%' }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="tenantName"
                    label="組織名"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={newCreateTenantName}
                    onChange={(e) => setNewCreateTenantName(e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow
                key="plan"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word', width: '30%' }}
                >
                  プラン（必須）
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word', width: '70%' }}>
                  {loading ? (
                    <CircularProgress />
                  ) : error ? (
                    <div>エラーが発生しました</div>
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel id="plan-label">プラン</InputLabel>
                      <Select
                        labelId="plan-label"
                        id="plan"
                        value={newCreatePlan}
                        onChange={(e) => setNewCreatePlan(e.target.value)}
                      >
                        {data &&
                          data.map((plan: PlanResponse) => (
                            <MenuItem key={plan.id} value={plan.id}>
                              {plan.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </TableCell>
              </TableRow>
              <TableRow
                key="address"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word', width: '30%' }}
                >
                  住所（必須）例: 東京都八王子市中町1-1
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word', width: '70%' }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="address"
                    label="住所"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={newCreateAddress}
                    onChange={(e) => setNewCreateAddress(e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow
                key="shippingAddress"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word', width: '30%' }}
                >
                  配送先住所（任意） 例: 東京都八王子市中町1-1 ※
                  こちらの住所へデバイスの配送を行います。住所と異なる場合はご記載下さい
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word', width: '70%' }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="shippingAddress"
                    label="配送先住所"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={newCreateShippingAddress}
                    onChange={(e) =>
                      setNewCreateShippingAddress(e.target.value)
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow
                key="phoneNumber"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ wordBreak: 'break-word', width: '30%' }}
                >
                  電話番号（必須）例: 09012345678 ※ ハイフンなしで入力して下さい
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-word', width: '70%' }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="phoneNumber"
                    label="電話番号"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={newCreatePhoneNumber}
                    onChange={(e) => setNewCreatePhoneNumber(e.target.value)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          <Grid item>
            <Button
              variant="contained"
              sx={{ marginRight: 2 }}
              onClick={handleBack}
            >
              戻る
            </Button>
            <Button variant="contained" onClick={handleCreateConfirm}>
              確定
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterTenantAccount;
