import React from 'react';
import Layout from '../../components/common/Layout';
import RcEquipmentsTable from '../../components/dataDisplay/RcEquipmentsTable';

const RcEquipmentsPage: React.FC = () => {
  return (
    <Layout>
      <RcEquipmentsTable />
    </Layout>
  );
};

export default RcEquipmentsPage;
