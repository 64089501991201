import { useState, useEffect } from 'react';
import axios from 'axios';
import { PlanResponse } from '../types';

const useFetchPlansData = (url: string, fetchOnMount: boolean = true) => {
  const [data, setData] = useState<PlanResponse[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(url);
      setData(response.data);
    } catch (error) {
      setError('データの取得に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fetchOnMount) {
      fetchData();
    }
  }, [url]);

  return { data, loading, error, refetch: fetchData };
};

export default useFetchPlansData;
