import { useState } from 'react';
import axios from 'axios';
import { UpdateCamera } from '../types';

const useUpdateCameraData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const updateCamera = async (
    url: string,
    token: string,
    data: UpdateCamera,
  ) => {
    try {
      setLoading(true);
      const response = await axios.put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (e) {
      setError(e as Error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { updateCamera, loading, error };
};

export default useUpdateCameraData;
