import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userIdState } from '../../../state/user/userState';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import useFetchUserData from '../../../hooks/useFetchUserData';
import useFetchCamerasData from '../../../hooks/useFetchCamerasData';
import { CameraResponse } from '../../../types';

const drawerWidth = 240;

interface NavigationBarProps {
  onCameraSelect?: (cameraId: string | null) => void;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ onCameraSelect }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem('token') || '';
  const userId = useRecoilValue(userIdState);

  if (
    location.pathname === '/images' ||
    location.pathname === '/users' ||
    location.pathname === '/cameras' ||
    location.pathname === '/tenant-account' ||
    location.pathname === '/user-account' ||
    location.pathname === '/rc-equipments'
  ) {
    return (
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            top: '64px',
            height: `calc(100% - 64px)`,
          },
        }}
      >
        <List>
          <ListItem button onClick={() => navigate('/dashboard')}>
            <ListItemText primary="ダッシュボード" />
          </ListItem>
        </List>
      </Drawer>
    );
  }

  const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api/v1';
  const userUrl = `${API_ENDPOINT}/users/${userId}`;
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useFetchUserData(userUrl, token, false);

  const url = userData
    ? `${API_ENDPOINT}/cameras/tenant/${userData.userTenant.tenantId}`
    : '';
  const { data, loading, error } = useFetchCamerasData(url, token, false);

  if (userLoading || loading) {
    return <CircularProgress />;
  }
  if (
    userError &&
    userError.message !== 'Request failed with status code 401' &&
    userError.message !== 'Request failed with status code 404'
  ) {
    return <p>Error: {userError.message}</p>;
  }
  if (
    error &&
    error.message !== 'Request failed with status code 401' &&
    error.message !== 'Request failed with status code 404'
  ) {
    return <p>Error: {error.message}</p>;
  }

  let cameras: CameraResponse[] =
    data && Array.isArray(data.cameras)
      ? data.cameras.map((cameraData: CameraResponse) => ({
          id: cameraData.id,
          cameraSid: cameraData.cameraSid,
          name: cameraData.name,
          description: cameraData.description,
          antennaId: cameraData.antennaId,
          tenantId: cameraData.tenantId,
        }))
      : [];
  cameras = cameras.sort((a, b) => a.name.localeCompare(b.name, 'ja'));

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          top: '64px',
          height: `calc(100% - 64px)`,
        },
      }}
    >
      <List>
        <ListItem
          button
          key="recent-data"
          onClick={() => onCameraSelect && onCameraSelect(null)}
        >
          <ListItemText primary="最近のデータ" />
        </ListItem>
        {loading ? (
          <ListItem>
            <ListItemText primary="Loading..." />
          </ListItem>
        ) : (
          cameras.map((camera: CameraResponse) => (
            <ListItem
              button
              key={camera.id}
              onClick={() => {
                if (onCameraSelect) {
                  onCameraSelect(camera.cameraSid);
                }
              }}
            >
              <ListItemText primary={camera.name} />
            </ListItem>
          ))
        )}
      </List>
    </Drawer>
  );
};

export default NavigationBar;
